<template>
  <div class="login-wrapper">
    <div class="login-logo-cell" @click="$router.push('/')">
      <img class="logo" src="@/assets/img/logowhite.png" />
      <div class="logo-label">专注北美计算机留学求职</div>
    </div>
    <div class="login-panel">
      <div class="login-left">
        <div class="l-title">打开微信右上角【扫一扫】 <br />完成注册/登录</div>
        <div class="login-wx">
          <img
            :src="qrcode && load ? qrcode : require('@/assets/img/loading.gif')"
            @click="loading"
          />
        </div>
        <div class="wx-bar">
          <img src="@/assets/images/login/wx.png" alt="" /><span>微信扫码</span>
        </div>
      </div>
      <div class="flex_bd login-right">
        <el-tabs v-model="activeName" @tab-click="changeTab">
          <el-tab-pane label="验证码登录" name="first">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item prop="phone">
                <div
                  class="input-select-group"
                  :class="'select' + ruleForm.select"
                >
                  <el-select
                    v-model="ruleForm.select"
                    slot="prepend"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in countrys"
                      :label="item.label"
                      :key="item.val"
                      :value="item.val"
                    ></el-option>
                  </el-select>
                  <div class="accountSeperator"></div>
                  <div class="flex-col">
                    <el-input
                      v-model="ruleForm.phone"
                      placeholder="请输入手机号"
                    ></el-input>
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  v-model="ruleForm.code"
                  placeholder="请输入4位短信验证码"
                >
                  <el-button
                    slot="suffix"
                    class="btn btn-getcode"
                    @click="sendVerificationCode"
                    :disabled="sended"
                    >{{
                      sended ? `${countDown}秒后再次发送` : "获取短信验证码"
                    }}</el-button
                  >
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="btn-submit"
                  type="primary"
                  @click="submitForm('ruleForm')"
                  >登录/注册</el-button
                >
              </el-form-item>
              <div class="agr-cell">
                <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
                <div class="agr-label">
                  <router-link to="/question?type=7">《用户协议》</router-link>
                  <router-link to="/question?type=9">《隐私政策》</router-link
                  >及
                  <router-link to="/question?type=8">《版权保护》</router-link>
                </div>
              </div>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="密码登录" name="second">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm2">
              <el-form-item prop="account">
                <div
                  class="input-select-group"
                  :class="'select' + ruleForm.select"
                >
                  <el-select
                    v-if="foreignAccount"
                    v-model="ruleForm.select"
                    slot="prepend"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in countrys"
                      :label="item.label"
                      :key="item.val"
                      :value="item.val"
                    ></el-option>
                  </el-select>
                  <div class="accountSeperator"></div>
                  <div class="flex-col">
                    <el-input
                      v-model="ruleForm.account"
                      :placeholder="
                        foreignAccount ? `请输入手机号` : `请输入手机号/邮箱`
                      "
                    ></el-input>
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  v-model="ruleForm.password"
                  placeholder="请输入密码"
                  type="password"
                ></el-input>
              </el-form-item>
              <div class="find-pwd">
                <div
                  style="color: #007df7; cursor: pointer"
                  @click="foreignAccount = !foreignAccount"
                >
                  {{ foreignAccount ? "邮箱账号登录" : "海外手机号登录" }}
                </div>
                <router-link
                  to="/findpassword"
                  style="color: #007df7; cursor: pointer"
                  target="_blank"
                >
                  找回密码
                </router-link>
              </div>
              <el-form-item>
                <el-button
                  class="btn-submit"
                  type="primary"
                  @click="
                    submitForm(activeName == 'first' ? 'ruleForm' : 'ruleForm2')
                  "
                  >{{ activeName == "first" ? "登录/注册" : "登录" }}</el-button
                >
              </el-form-item>
              <div class="agr-cell">
                <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
                <div class="agr-label">
                  <router-link to="/question?type=7">《用户协议》</router-link>
                  <router-link to="/question?type=9">《隐私政策》</router-link
                  >及
                  <router-link to="/question?type=8">《版权保护》</router-link>
                </div>
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <tipsDialog
      ref="tipsDialog"
      :visible="tipVisible"
      @changeVisible="(val) => (tipVisible = val)"
    ></tipsDialog> -->
  </div>
</template>

<script>
import {
  qrCode,
  checkScene,
  sendVerificationCode,
  verifyCodeLogin,
  applyLogin,
  getUserStatusData,
} from "@/service/index";
import { Loading } from "element-ui";
export default {
  components: {},
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      setTimeout(() => {
        if (!regex.test("+" + this.ruleForm.select + value)) {
          return callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 1000);
    };
    var checkAccount = (rule, value, callback) => {
      if (!value) {
        return callback(
          new Error(
            this.foreignAccount ? "手机号不能为空" : "手机号/邮箱不能为空"
          )
        );
      }
      const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      setTimeout(() => {
        if (
          !regex.test("+" + this.ruleForm.select + value) &&
          !emailRegex.test(value)
        ) {
          return callback(
            new Error(
              this.foreignAccount
                ? "请输入正确的手机号"
                : "请输入正确的手机号/邮箱"
            )
          );
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      checked: false,
      activeName: "first",
      tipVisible: false,
      ruleForm: {
        phone: "",
        code: "",
        account: "",
        password: "",
        select: "86",
      },
      rules: {
        phone: [{ required: true, validator: checkMobile, trigger: "blur" }],
        code: [
          { required: true, message: "请输入4位短信验证码", trigger: "blur" },
        ],
        account: [{ required: true, validator: checkAccount, trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
      qrcode: "",
      qrCodeTimer: null,
      reloadQrCodeTimer: null,
      reload: true,
      load: false,
      countrys: [
        {
          label: "+86 中国",
          val: "86",
        },
        {
          label: "+1 美国/加拿大",
          val: "1",
        },
        {
          label: "+61 澳大利亚",
          val: "61",
        },
        {
          label: "+44 英国",
          val: "44",
        },
        {
          label: "+49 德国",
          val: "49",
        },
        {
          label: "+353 爱尔兰",
          val: "353",
        },
        {
          label: "+41 瑞士",
          val: "41",
        },
        {
          label: "+31 荷兰",
          val: "31",
        },
        {
          label: "+33 法国",
          val: "33",
        },
        {
          label: "+34 西班牙",
          val: "34",
        },
        {
          label: "+64 新西兰",
          val: "64",
        },
        {
          label: "+7 俄罗斯",
          val: "7",
        },
        {
          label: "+82 韩国",
          val: "82",
        },
        {
          label: "+81 日本",
          val: "81",
        },
        {
          label: "+971 阿拉伯联合酋长国",
          val: "971",
        },
        {
          label: "+886 台湾",
          val: "886",
        },
        {
          label: "+852 香港",
          val: "852",
        },
      ],
      countDown: 60,
      sended: false,
      foreignAccount: false,
    };
  },
  created() {
    this.loading();
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.qrCodeTimer);
    this.qrCodeTimer = null;
    clearInterval(this.reloadQrCodeTimer);
    this.reloadQrCodeTimer = null;
  },
  methods: {
    loading() {
      this.load = false;
      this.reload = true;
      clearInterval(this.reloadQrCodeTimer);
      this.reloadQrCodeTimer = null;
      this.qrcode = require("@/assets/img/loading.gif");

      setTimeout(() => {
        this.getQrCode();
      }, 1000);
    },
    getQrCode() {
      qrCode()
        .then((res) => {
          this.qrcode = res.result.qrCodeUrl;
          this.load = true;
          this.startLogin(res.result.ticket);
          this.reloadQrCode(res.result.expireSeconds);
        })
        .catch((err) => {});
    },
    reloadQrCode(expireSeconds) {
      this.reloadQrCodeTimer = setInterval(() => {
        clearInterval(this.qrCodeTimer);
        this.reload = false;
      }, parseInt(expireSeconds) * 1000);
    },
    submitForm(formName) {
      if (!this.checked) {
        return this.$message.warning("请先阅读并勾选协议");
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.activeName == "first") {
              verifyCodeLogin({
                phoneOrEmail: `${this.ruleForm.select}${this.ruleForm.phone}`,
                code: this.ruleForm.code,
              }).then((res) => {
                if (res.success) {
                  this.$message.success("登录成功");
                  window.localStorage.setItem(
                    "CSON_PAGE_TOKEN",
                    res.result.token
                  );
                  window.location.href = "/";
                } else {
                  this.isLoading = false;
                  this.$message.closeAll();
                  this.$message.error(res.message);
                }
              });
            } else {
              console.log("???");
              this.slideValidation(() => {
                const emailRegex =
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                applyLogin({
                  phoneOrEmail: this.foreignAccount
                    ? `${this.ruleForm.select}${this.ruleForm.account}`
                    : `${
                        emailRegex.test(this.ruleForm.account)
                          ? this.ruleForm.account
                          : `86${this.ruleForm.account}`
                      }`,
                  password: this.ruleForm.password,
                }).then((res) => {
                  if (res.success) {
                    this.$message.success("登录成功");
                    window.localStorage.setItem(
                      "CSON_PAGE_TOKEN",
                      res.result.token
                    );
                    window.location.href = "/";
                  } else {
                    this.isLoading = false;
                    this.$message.closeAll();
                    this.$message.error(res.message);
                  }
                });
              });
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    startLogin(scene) {
      window.localStorage.removeItem("CSON_PAGE_TOKEN");
      this.qrCodeTimer = setInterval(() => {
        checkScene(
          scene,
          this.$route.query.invitationEmailCode
            ? this.$route.query.invitationEmailCode
            : ""
        )
          .then((res) => {
            if (res.result) {
              window.localStorage.setItem("CSON_PAGE_TOKEN", res.result.token);
              this.$nextTick(() => {
                getUserStatusData().then((userInfo) => {
                  if (userInfo.result.phone) {
                    window.location.href = "/";
                  } else {
                    this.$router.push("/bindPhone");
                  }
                });
              });
            }
          })
          .catch((err) => {});
      }, 1000);
    },

    sendVerificationCode() {
      if (this.ruleForm.phone == "") {
        return this.$message.error("手机号不能为空！");
      }
      const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      if (!regex.test(`+${this.ruleForm.select}${this.ruleForm.phone}`)) {
        return this.$message.error("请输入正确的手机号！");
      }
      this.slideValidation(() => {
        sendVerificationCode(
          `${this.ruleForm.select}${this.ruleForm.phone}`
        ).then((res) => {
          if (res.success) {
            this.$message.success("发送验证码成功！");
            this.sended = true;
            const countDown = setInterval(() => {
              this.countDown = this.countDown - 1;
              if (this.countDown == 0) {
                clearInterval(countDown);
                this.countDown = 60;
                this.sended = false;
              }
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    slideValidation(cb) {
      let captchaId = "2060393098";
      const captcha = new TencentCaptcha(captchaId, async (res) => {
        console.log(res);
        if (res.ret === 0) {
          cb();
        }
      });
      captcha.show();
    },
    changeTab() {
      if (this.activeName == "first") {
        this.ruleForm = {
          phone: "",
          code: "",
          account: "",
          password: "",
          select: "86",
        };
      } else {
        this.ruleForm = {
          phone: "",
          code: "",
          account: "",
          password: "",
          select: "1",
        };
      }

      this.$refs.ruleForm.clearValidate();
      this.$refs.ruleForm2.clearValidate();
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.login-logo-cell {
  text-align: center;
  margin-bottom: 30px;
  color: #ffffff;
  cursor: pointer;
}
.login-logo-cell .logo {
  height: 42px;
}
.btn-getcode {
  border: 0;
  margin: 3px 5px;
  color: #007df7;
  padding: 8px 10px;
  &.is-disabled {
    color: #c0c4cc;
  }
}
.agr-cell ::v-deep .el-checkbox__label {
  color: #666 !important;
  font-size: 12px;
}
.agr-label {
  color: #007bf7;
  font-size: 12px;
}
.agr-label a {
  color: #007bf7;
}
.agr-cell {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.login-right ::v-deep .el-input__inner {
  background: #f8f8f8;
  border-radius: 10px;
  border-color: #f8f8f8 !important;
  font-size: 14px;
}
.login-right .btn-submit {
  width: 100%;
  background: linear-gradient(90deg, #007bf7 0%, #0aabfb 100%);
  border-radius: 10px;
}
.flex_bd {
  flex: 1;
  min-width: 0;
}
.login-right ::v-deep .el-tabs__item {
  font-size: 16px;
}
.login-right ::v-deep .el-tabs__header {
  margin-bottom: 20px !important;
}
.login-wrapper {
  position: relative;
  z-index: 1002;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(../../../assets/images/login/bg.jpg) center no-repeat;
  background-size: cover;
  height: 100vh;
}
.login-panel {
  display: flex;
  align-items: center;
  width: 720px;
  height: 360px;
  background: #ffffff;
  border-radius: 10px;
  padding: 30px 50px;
}
.login-left {
  text-align: center;
  border-right: 1px solid #eceef2;
  padding-right: 40px;
  margin-right: 40px;
  .l-title {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .login-wx {
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    font-size: 0;
    border: 1px solid #eceef2;
    margin-bottom: 20px;
    background: #fff;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .wx-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
    }
  }
}
.input-select-group {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 10px;
  border-color: #f8f8f8 !important;
  position: relative;
}
.input-select-group .el-select {
  width: 110px;
}
.input-select-group.select1 .el-select {
  width: 146px;
}
.input-select-group.select353 .el-select,
.input-select-group.select34 .el-select,
.input-select-group.select64 .el-select {
  width: 130px;
}
.input-select-group.select61 .el-select {
  width: 136px;
}
.input-select-group.select971 .el-select {
  width: 200px;
}

.accountSeperator {
  background: #ebeced;
  height: 22px;
  width: 1px;
}
.flex-col {
  flex: 1;
  min-width: 0;
}
.find-pwd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

::v-deep .el-input-group__prepend div.el-select .el-input__inner {
  border: none !important;
}
::v-deep .el-input-group__prepend {
  border: none;
}
</style>
